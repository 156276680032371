<template>
  <div v-if="course_type_id == 1 && vip_level == 0">
    <el-dialog
        title="填寫登記的電話號碼即可激活團隊及付費vip"
        v-model="delete_task"
        top="35vh"
        width="90%">
        <span class="dialog-footer">
          <el-button @click="task_confirm">確 定</el-button>
          <el-button type="primary" @click="delete_task = false">取 消</el-button>
        </span>
    </el-dialog>
    <Header/> 
    <div style="margin-top: 7.5vh;">
      <el-image   :class="{detail_1_s:port == 'pc' || port == 'iPad'}" :src="logo" :preview-src-list="[logo]" v-if="!video"></el-image>
        <VuePlayerVideo :class="{video_s:port == 'pc'}" v-else :src="video"></VuePlayerVideo>
    </div>
    <div class="wux-padding--15" >
      <img src="../../assets/topic/text_logo.jpg">
      <div>此課程內容只開放給相關線下付費課會員</div>
      <div class="topic_kc">
        <button class="round-btn_i un_button" @click="delete_task = true">激活VIP功能</button>
        <div style="margin-top: 10px;"><p>已付費用戶或團隊用戶</p><p>補充資料以開啟VIP功能</p></div>
      </div>
    </div>
  </div>
  <div v-else-if="is_need_buy == 0">
    <Header/> 
    <div style="margin-top: 7.5vh;">
        <el-image   :class="{detail_1_s:port == 'pc' || port == 'iPad'}" :src="logo" :preview-src-list="[logo]" v-if="!video"></el-image>
        <VuePlayerVideo :class="{video_s:port == 'pc'}" v-else :src="video"></VuePlayerVideo>
    </div>
    <div style="width:100%;height:auto">
      <div style="margin-top: 15px;"></div>
      <div class="lesson-tools">
        <div style="height: 26px; margin-left: auto;" @click="collect">
          <div style="display: flex;" v-if="!user_is_favorite">
            <button class="button">加書簽</button>
            <img src="../../assets/training_schedule/detail/unmark_icon.png" style="margin-right: 16px;width: 16px;height: 26px;">
          </div>
          <div style="display: flex;" v-else>
            <button class="button">已收藏</button>
            <img src="../../assets/lesson/mark_icon.png" style="margin-right: 16px;width: 16px;height: 26px;">
          </div>
        </div>
        <div>
              <button class="clear-btns round-btn" style="padding: 7px 10px;" @click="accomplish" v-if="!user_is_completion">完成 >></button>
              <p style="padding: 7px 10px;color:#fff;" @click="accomplish" v-else >已完成</p>
          </div>
      </div>
      <div v-html="content" class="training_schedule_conten"></div>

      <div class="files-con" style="text-align:left;color:#fff;" v-if="attachment">
        <div style="padding: 15px;">附件：<p style="font-size: 80%;">你可以點擊文件名直接查看或複制鏈接到其他工具下載</p></div>
        <div style="padding: 10px 8px;">
          <a :href="attachment" target="_blank">
          <div style="width:68%;height:auto;float:left;">
            <div class="imgs_one" style="float:left;">
              <img :src="pdf" style="width:100%;height:100%;">
            </div>
            <div class="imgs_two" style="float:left;">
              {{attachment_name}}
            </div>
          </div>
          </a>
          <div style="float:left;margin-left:10px;width:28%;height:auto;">
            
            <button class="round-btn" @click="copy_url()">複制鏈接</button>
          </div>
          <!-- <div>
            <img :src="pdf" class="imgs_one" >
             <p>卓智投資220210827155911.pdf</p>
          </div>
          <div class="grace-list-body">
            <div class="grace-list-title">
              <div class="grace-list-title-text">
                <span>卓智投資220210827155911.pdf</span>
              </div>

              <div class="grace-list-title-desc">
                <button class="round-btn">複制鏈接</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div style="height:40px;"></div>
    </div>
  </div>
  <div v-else>
    <Header/>
    <isNeedBuy/>
  </div>
</template>
<style>
.round-btn{
  float:right;
  padding: 7px 10px;
    font-size: 13px;
    display: block;
    color: #fff;
    line-height: 130%;
    font-size: 14px;
    padding: 7px 16px;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
    -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
    transition: all .3s cubic-bezier(.215,.61,.355,1);
}
.grace-list-title-desc{
  display: block;
    font-size: 11px;
    color: #999;
    line-height: 16px;
    margin-left: 10px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}
.grace-list-title-text{
  padding: 0px;
    font-weight: normal;
    font-family: Helvetica Neue;
    color: #fff;
    text-align: left /* height: 100%; */;
}
.grace-list-title{
  display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.grace-list-body {
  display: block;
  padding: 13px 0;
  margin-left: 13px;
  width: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: auto;
  flex: auto;
}
.imgs_one{
  width: 40px;
  height: 50px;
}
.imgs_two{
  font-size: 13px;
  width: 185px;
  height: 50px;
  margin-left:10px;
  line-height:30px;
  word-break: break-all;
}
.files-con{
  width: 100%;
  border-top: 1px solid #fff;
  margin: 30px 0px;
}
.video_s{
  width:50%;
}
.detail_imgs{
  width:100%;
  height:100%;
}
.detail_image{
  width:100%;
  height:70%;
}
.vue3-player-video .rounded-3xl {
    border-radius: 0rem;
}
.el-dialog__header{
    padding-top: 5vh;
}
.un_button{
  width:100%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  line-height: 2.55555556;
  border-radius: 5px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  color: #000;
  background-color: #f8f8f8;
  cursor: pointer;
}
.round-btn_i{
  display: block;
  color: #fff;
  line-height: 130%;
  font-size: 16px;
  padding: 7px 20px;
  border: 1px solid #fff;
  border-radius: 999999px;
  background-color: initial;
  -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
  transition: all .3s cubic-bezier(.215,.61,.355,1);
}
.topic_kc{
  margin: 30px;
  text-align: center;
}
.wux-padding--15{
  padding: 14px!important;
  text-align: center!important;
  color:#fff;
}
.training_schedule_conten{
  margin: 20px;
  text-align: left;
  color: #fff;
  word-break: break-all;
  font-size: 18px;
}
.round-btn img{
  margin-right: 5px;
}
.grace-list-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.grace-list-title-desc{
  display: block;
  font-size: 10px;
  color: #999;
  line-height: 15px;
  margin-left: 9px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.grace-list-title-text {
    display: block;
    font-size: 12px!important;
    /* color: #333; */
    line-height: 19px;
    padding: 0px;
    font-weight: normal;
    width:50%;
    height:auto;
    color:#fff;
}
.grace-list-body{
  padding: 12px 0;
  margin-left: 12px;
  width: 48px;
  -webkit-box-flex: 1;
  flex: auto;
}
.grace-border-b {
    border-bottom: 1px solid #e9e9e9;
}
.grace-list-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.lesson-content {
  padding: 15px;
  padding-top: 0;
  font-family: Helvetica Neue;
  font-weight: 300;
  font-size: 16px;
  text-align: left /* height: 100%; */;
}
.lesson-title{
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  font-family: Helvetica Neue;
  font-weight: 400;
  font-size: 24px;
  text-align: left;
  color:#fff;
}
.round-btn{
  color: #fff;
  line-height: 130%;
  border: 1px solid #fff;
  border-radius: 999999px;
  background-color: initial;
}
.clear-btns{
  background: transparent;
}
.button{
  font-weight: 300;
  font-size: 14px;
  color: rgb(255, 255, 255);
  margin-right: 6px;
  line-height: 0;
  padding: 0;
  background: transparent;
  border: none;
}
.lesson-tools{
  height: 40px;
  margin-bottom: 10px;
  margin-right: 17px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
  .detail_1{
    width: 100%;
    height: 32vh;
    margin-top: 7.5vh;
  }
  .detail_1_s{
    width: 100%;
    height: 35vh;
  }
</style>
<script>
import Header from "../Header";
import isNeedBuy from "../s_topic/detail";
import VuePlayerVideo from 'vue3-player-video';
import {apiCoursesDetail,apiUsersDetail,apiUserCourseFavorites,apiUserDeleteCourseFavorites,apiUserCourseCompletions,apiCourseTypesDetail} from "@/request/api";
export default {
  components:{Header,isNeedBuy,VuePlayerVideo},
  data(){
    return {
      img:'',
      logo:'',
      content:'',
      // 是否需要购买 1是需要 0是不需要
      is_need_buy:0,
      // 是否需要激活vip 1是需要 0是不需要
      course_type_id:0,
      vip_level:0,
      vip:true,
      delete_task:false,
      // 是否完成 0：未完成 1：已完成
      user_is_completion:0,
      // 是否收藏 0：未收藏 1：已收藏
      user_is_favorite:0,
      lang_id:0,
      video:'',
      port:'',
      pdf:require('@/assets/content/file_pdf.png'),
      attachment:'',
      attachment_name:'',
    }
  },
  created(){
    let id = this.$route.params.id;
    let lang_id = this.$store.state.lang_id;
    if (!this._isMobile()) {
      this.port = 'pc';
    } else if(this._isMobile()[0] == 'iPad'){
      this.port = 'iPad';
    }else{
      this.port = 'phone';
    }
    this.lang_id = lang_id;   
    let that = this;
    if(id && id > 0){
      apiCoursesDetail(id,{
        lang_id:lang_id,
        user_id:this.$store.state.user_id,
      }).then(res=>{
        if(res.data){
          that.video = res.data.video;
          let is_need_buy = res.data.is_need_buy;
          that.is_need_buy = is_need_buy;
          that.logo = res.data.cover;
          that.content = res.data.content;
          that.course_type_id = res.data.is_activate_vip;
          that.user_is_completion = res.data.user_is_completion;
          that.user_is_favorite = res.data.user_is_favorite;
          that.attachment = res.data.attachment;
          that.attachment_name = res.data.attachment_name;
          // 如果直接访问详情页 则就去查询当前详情页上一级课堂是否是在职培训 如果是则判断是否已经登录了
          if(this.$store.state.parent_course_type_id != '34'){
              this.apiCourseTypesDetails(res.data.course_type_id);
          }
        }
      }).catch(error=>{
        console.log(error);
      })
      
      // 在职培训 需要登录 其他页面查看内容不需要登录
      if((this.$store.state.user_id && this.$store.state.user_id != "null") || this.$store.state.parent_course_type_id == '34'){
        apiUsersDetail(this.$store.state.user_id).then(res=>{
          if(res.data){
            that.vip_level = res.data.vip_level;
          }
        })
      }
    }
  },
  methods:{
    // 查询当前课程页的上一页课堂 因为上一级可以有很多层 所以一层一层往上差直到过滤掉所有课堂或者查询到课堂为 在职培训
    apiCourseTypesDetails(id){
      let lang_id = this.$store.state.lang_id;
       apiCourseTypesDetail(id,{
        id:id,
        lang_id:lang_id
      }).then(res=>{
        if(res.code == 200){
          this.$store.state.parent_course_type_id = res.data.parent_id;
          if(res.data.parent_id){
            if(res.data.parent_id == '34'){
              if(!this.$store.state.user_id || this.$store.state.user_id == 'null'){
                this.$message.error("登錄異常，請重新登錄");
                this.$router.push({path:'/login'});
                // location.reload();
                location.href=document.location.protocol+'//'+window.location.host+"/login";
              }
            }else{
              this.apiCourseTypesDetails(res.data.parent_id);
            }
          }
        }else{
          this.$message.error(res.message);
          this.$router.push({path:'/'});
          return false;
        }
      }).catch(error=>{
        console.log(error);
      })
    },
    copy_url(){
      this.copy(this.attachment);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    task_confirm(){
      this.$router.push({
        path:'/my/account',
        query:{
          type:'vip',
        }
      })
    },
    collect(){
      let id = this.$route.params.id;
      if(id && id > 0){
        if(this.user_is_favorite){
          apiUserDeleteCourseFavorites(id).then(res=>{
            if(res.code == 200){
              this.$message.success({message: "課程已取消收藏", duration: 10000});
              this.user_is_favorite = 0;
            }else{
              this.$message.error({message: res.message, duration: 10000});
            }
          })
        }else{
          apiUserCourseFavorites({
              course_id:id
          }).then(res=>{
            if(res.code == 200){
              this.$message.success({message: "課程已收藏", duration: 10000});
              this.user_is_favorite = 1;
            }else{
              this.$message.error({message: res.message, duration: 10000});
            }
          })
        }
      }
    },
    accomplish(){
      let user_is_completion = this.user_is_completion;
      let id = this.$route.params.id;
      if(id && id > 0){
        if(!user_is_completion){
          apiUserCourseCompletions({
            course_id:id
          }).then(res=>{
            if(res.code == 200){
              this.$message.success({message: "已完成", duration: 10000});
              this.user_is_completion = 1;
            }else{
              this.$message.error({message: res.message, duration: 10000});
            }
            this.$router.push({
              path:'/lesson_done/'+id
            });
          }).catch(error=>{
            console.log(error);
          })
        }
      }
      if(user_is_completion){
        this.$router.push({
          path:'/lesson_done/'+id
        });
      }
    },
    copy(data){
      let url = data
      let oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand("Copy") // 执行浏览器复制命令
      this.$message({
        message: '下載的連結已複製，請通過你的wechat, whatapp 或電郵傳到電腦中進行下載。',
        type: 'success'
      })
      oInput.remove()
    }
  }
}
</script>

