<template>
  <div >
    <Header/> 
   
    <div class="svsvsv">
        <div class="wux-padding--15s">
              <img src="../../assets/topic/text_logo.jpg">
              <div>此課程內容需要付費</div>
              <div class="topic_kcs">
                <router-link :to="{path:'/vippay/'+id}">
                    <button class="clear-btn_1 round-btn_1 button_detail" id="account">
                        <img src="../../assets/s_topic/logo.png">進入VIP產品購買
                    </button>
                </router-link>
                <div style="margin-top: 5vh;" class="gui-flex gui-rows gui-nowrap gui-align-items-center">
                    <p class="gui-title-line"></p>
                    <p class="gui-h6" v-if="login_status">當前已登入帳戶</p>
                    <p class="gui-h6" v-else>已有賬戶</p>
                    <p class="gui-title-line"></p>
                </div>
                <div style="margin-top: 10px; border: 1px dashed rgba(255, 255, 255, 0.7); padding: 15px;" v-if="login_status">
                    <div>{{user_name}}</div>
                    <div>{{user_email}}</div>
                </div>
                <div style="margin-top: 10px; border: 1px dashed rgba(255, 255, 255, 0.7); padding: 15px;" v-else>
                    <div>公司可能已爲你分配賬戶,</div>
                    <div>或者你之前已經注冊過賬戶</div>
                </div>
                <button class="clear-btn_1 round-btn_1 button_detail"  @click="switchAccount" v-if="login_status">嘗試用登入另一個帳戶</button>
                <router-link :to="{path:'/login'}">
                    <button class="clear-btn_1 round-btn_1_s button_detail" v-if="!login_status"><img src="../../assets/s_topic/qyyh.png">企業用戶</button>
                    <button class="clear-btn_1 round-btn_1_s button_detail" v-if="!login_status"><img src="../../assets/s_topic/gryh.png">個人用戶</button>
                </router-link>
              </div>
          </div>
        <div style="width:100%;height:90px;"></div>
    </div>

  </div>
</template>

<style>
#account{
padding: 1.5vh 17.5vw;
}
.button_detail{
    margin-top:3vh;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    line-height: 2.55555556;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    color: #000;
    background-color: #f8f8f8;
    cursor: pointer;
}
.clear-btn_1{
    background: transparent;
}
.round-btn_1{
    width: 100%;
    display: flex;
    color: #fff;
    line-height: 130%;
    font-size: 16px;
    padding: 1.5vh 15.2vw;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
    transition: all .3s cubic-bezier(.215,.61,.355,1);
}
.round-btn_1_s{
    width: 100%;
    display: flex;
    color: #fff;
    line-height: 130%;
    font-size: 16px;
    padding: 1.5vh 25vw;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
    transition: all .3s cubic-bezier(.215,.61,.355,1);
}
.gui-h6{
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
}
.gui-title-line{
    width: 24px;
    height: 1px;
    background-color: #e1e2e3;
    -webkit-box-flex: 1;
    display: block;
    flex: 1;
}
.gui-flex{
    display: flex;
}
.gui-nowrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.gui-align-items-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.topic_kcs{
  margin: 30px;
  text-align: center;
}
.wux-padding--15s{
    color:#fff;
    padding: 14px!important;
    margin-top: 2vh;
    text-align: center!important;
}
.svsvsv{
    height: auto;
    width: 100%;
}
</style>
<script>
import Header from "../Header";
import {apiUsersDetail,apiLogOut} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        user_name:"",
        user_email:'',
        id:0,
        login_status:true,
    }
  },
  methods:{
    switchAccount(){
        // 登出
        apiLogOut().then(res=>{
            if(res.code == 200){
            localStorage.removeItem('token');
            localStorage.removeItem('user_avatar');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_name');
            this.$message.success('登出成功');
            location.href="../login";
            }else{
                this.$message.error(res.message);
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    }
  },
  created(){
      let id = this.$route.params.id;
      this.id = id;
      let user_id = this.$store.state.user_id;
      if(!user_id){
          this.login_status = false;
      }
      apiUsersDetail(user_id).then(res=>{
          if(res.code == 200){
            this.user_name = res.data.name;
            this.user_email = res.data.email;
          }else{
            this.$message.error(res.message);
            return false;
          }
      }).then(error=>{
          console.log(error);
      })
  },
}
</script>

